import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Modal, Button } from 'react-bootstrap';

const obtenerFechaActual = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Agregar un cero delante si el mes o el día son menores que 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}-${month}-${day}`;
};

const AgregarProforma = ({clientes, subclientes, incoterms, puertos, productos, documentos, usuarios, categorias, especies, medidas, calidades, pt, proformas, cerrar}) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [fecha, setFecha] = useState(obtenerFechaActual());
    const [cliente, setCliente] = useState(0);
    const [subCliente, setSubCliente] = useState(null);
    const [fecha_embarque, setFechaEmbarque] = useState(null);
    const [payment_terms, setPaymentTerms] = useState(0);
    const [shipping_line, setShippingLine] = useState('');
    const [incoterm, setIncoTerm] = useState(0);
    const [puerto, setPuerto] = useState(0);
    const [direccion_destino, setDireccionDestino] = useState('');
    const [descripcion_producto, setDescripcionProducto] = useState('');
    const [productosp, setProductos] = useState([]);
    const [valor_proforma, setValorProforma] = useState(0);
    const [bank_information, setBankInformation] = useState('');
    const [beneficiary_bank, setBeneficiaryBank] = useState('');
    const [quality_standards, setQualityStandards] = useState('');
    const [tdocumentos, setDocumentos] = useState([]);
    const estado = 0
    const id = localStorage.getItem('ID_Usuario');
    const [remitente, setRemitente] = useState(parseInt(id));
    const [aprobador, setAprobador] = useState(0);
    const [observaciones_proforma, setObservacionesProforma] = useState('');
    const [observaciones_adicionales, setObservacionesAdicionales] = useState('');
    const [nuevoProducto, setNuevoProducto] = useState(null);
    const [nuevoDocumento, setNuevoDocumento] = useState(null);
    const [showSubClientes, setShowSubClientes] = useState(false);
    const [productosCliente, setProductosCliente] = useState([]);
    const [especificaciones_lc, setEspecificacionesLC] = useState('');
    const [indicador1, setIndicador1] = useState(false);
    const [indicador2, setIndicador2] = useState(false);
    const [indicador3, setIndicador3] = useState(false);
    const [indicador4, setIndicador4] = useState(false);
    const [advance, setAdvance] = useState(0);
    const [cad, setCAD] = useState(0);
    const [calidad, setCalidad] = useState([]);
    const [nuevaCalidad, setNuevaCalidad] = useState(null);
    const [productoSeleccionado, setProductoSeleccionado] = useState(null);
    const [calidadSeleccionada, setCalidadSeleccionada] = useState(null);
    const [productosSeleccionados, setProductosSeleccionados] = useState([]); // Para manejar la lista de productos agregados
    const [productosDisponibles, setProductosDisponibles] = useState([]);
    const [calidadesUsadas, setCalidadesUsadas] = useState([])
    const [indicadorIT, setIndicadorIT] = useState(null);
    const [origen, setOrigen] = useState('Brazil');
    const [proformaBase, setProformaBase] = useState('');
    const [allowance, setAllowance] = useState(0)
    const [moneda, setMoneda] = useState(0);
    const [esBorrador, setEsBorrador] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', body: '', variant: '' });
    const [correlativo, setCorrelativo] = useState(0);
    const [m3, setM3] = useState(''); 
    const [usd_m3, setUsdM3] = useState('');    
    const [editMode, setEditMode] = useState(false);
    const [productoEnEdicion, setProductoEnEdicion] = useState(null);
    const [empresaEmisor, setEmpresaEmisor] = useState(0);

    const showModalPopup = (title, body, variant) => {
        setModalContent({ title, body, variant });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        if(modalContent.variant === 'success'){
            window.location.reload()
        }
    }
    useEffect(() => {
        recalcularProductosDisponibles();
        setProductoSeleccionado(null);
    }, [productosSeleccionados, productosp]);

    useEffect(() => {
        if (proformaBase) {
            cargarProformaBase(proformaBase);
        }
    }, [proformaBase]);

    const getCurrencySymbol = (moneda) => {
        switch(moneda) {
            case 0: return 'USD';
            case 1: return 'EUR';
            case 2: return 'CLP';
            case 3: return 'BRL';
            case 4: return 'PYG';
            default: return 'USD';
        }
    };

    const cargarProformaBase = (proformaId) => {
        const proformaSeleccionada = proformas.find(p => p.id_proforma === parseInt(proformaId));
        if (proformaSeleccionada) {
            setCliente(proformaSeleccionada.cliente);
            setFechaEmbarque(proformaSeleccionada.fecha_embarque);
            setPaymentTerms(proformaSeleccionada.payment_terms);
            setShippingLine(proformaSeleccionada.shipping_line);
            setIncoTerm(proformaSeleccionada.incoterm);
            setPuerto(proformaSeleccionada.puerto);
            setDireccionDestino(proformaSeleccionada.direccion_destino);
            setDescripcionProducto(proformaSeleccionada.descripcion_producto);
            setProductos(proformaSeleccionada.productos.map((producto, index) => ({
                id_producto: producto,
                real_size: proformaSeleccionada.real_size[index],
                m3: proformaSeleccionada.m3[index],
                usd_m3: proformaSeleccionada.usd_m3[index],
                usd_total: proformaSeleccionada.usd_total[index],
                calidad: proformaSeleccionada.calidad[index]
            })));
            setValorProforma(proformaSeleccionada.valor_proforma);
            setBankInformation(proformaSeleccionada.bank_information);
            setBeneficiaryBank(proformaSeleccionada.beneficiary_bank);
            setQualityStandards(proformaSeleccionada.quality_standards);
            setDocumentos(proformaSeleccionada.documentos);
            setObservacionesProforma(proformaSeleccionada.observaciones_proforma);
            setObservacionesAdicionales(proformaSeleccionada.observaciones_adicionales);
            setEspecificacionesLC(proformaSeleccionada.especificaciones_lc);
            setAdvance(proformaSeleccionada.advance);
            setCAD(proformaSeleccionada.cad);
            setOrigen(proformaSeleccionada.origen);
            setMoneda(proformaSeleccionada.moneda);
            setAllowance(proformaSeleccionada.allowance);
            setEmpresaEmisor(proformaSeleccionada.empresa_emisor);
        }
    };

    const handleSubmit = (event, borrador) => {
        event.preventDefault();
        setBotonDeshabilitado(true);
    
        // Verificar si se debe guardar como borrador o como finalizado
        const estadoProforma = borrador ? 0 : 1;
    
        // Validación de campos obligatorios si no es borrador
        if (estadoProforma !== 0) {
            if (!fecha || !cliente || !fecha_embarque || !payment_terms || !incoterm || !puerto || !direccion_destino || !descripcion_producto || productosp.length === 0 || !valor_proforma) {
                alert('Faltan campos por completar.');
                setBotonDeshabilitado(false);
                return; // Detener el proceso si faltan campos
            }
        }
    
        // Inicializar arrays para los productos
        const arrayProductos = [];
        const arrayRealSize = [];
        const arrayM3 = [];
        const arrayUSDM3 = [];
        const arrayUSDTotal = [];
        const arrayCalidad = [];
    
        // Iterar sobre productosp para construir los arrays
        productosp.forEach(producto => {
            if (producto) {
                arrayProductos.push(producto.id_producto || 0);  // Asegúrate de que id_producto no sea undefined
                arrayRealSize.push(producto.real_size || '');  // Default a '' si no está definido
                arrayM3.push(producto.m3 || 0);  // Default a 0 si no está definido
                arrayUSDM3.push(producto.usd_m3 || 0);  // Default a 0 si no está definido
                arrayUSDTotal.push(producto.usd_total || 0);  // Default a 0 si no está definido
                arrayCalidad.push(producto.calidad || '');  // Default a '' si no está definido
            }
        });
    
        // Inicializar el array de documentos
        const arrayDocumentos = [];
        tdocumentos.forEach(doc => {
            arrayDocumentos.push(doc || '');  // Asegúrate de que los documentos no sean undefined
        });
    
        // Crear el objeto nuevo con todos los datos
        const nuevo = {
            fecha,
            cliente: parseInt(cliente),
            fecha_embarque,
            payment_terms,
            shipping_line,
            incoterm,
            puerto,
            direccion_destino,
            descripcion_producto,
            productos: arrayProductos,
            real_size: arrayRealSize,
            m3: arrayM3,
            usd_m3: arrayUSDM3,
            usd_total: arrayUSDTotal,
            valor_proforma,
            bank_information,
            beneficiary_bank,
            quality_standards,
            documentos: arrayDocumentos,
            estado: estadoProforma,
            remitente: parseInt(remitente),
            aprobador: parseInt(aprobador),
            observaciones_proforma,
            observaciones_adicionales,
            especificaciones_lc,
            advance,
            cad,
            calidad: arrayCalidad,
            origen,
            moneda,
            allowance,
            correlativo,
            empresa_emisor: parseInt(empresaEmisor),
        };
    
        const token = localStorage.getItem('token');
    
        // Enviar la solicitud de guardado de la proforma
        fetch('https://api.dvstarlab.com/agregarproforma', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(nuevo),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar datos');
            }
            return response.json();
        })
        .then(data => {
            showModalPopup('Éxito', 'Proforma agregada exitosamente', 'success');
            cerrar();
        })
        .catch(error => {
            console.error('Error:', error);
            showModalPopup('Error', 'Error guardando los datos', 'danger');
            cerrar();
        })
        .finally(() => {
            setBotonDeshabilitado(false);
        });
    };

    const seleccionCliente = (cliente) => {
        if (cliente !== 0) {
            const clienteSeleccionado = clientes.find(cli => cli.id_cliente === parseInt(cliente));
            if (clienteSeleccionado) {
                setCliente(clienteSeleccionado.id_cliente);
                seleccionIT(clienteSeleccionado.incoterm);
                setBankInformation(clienteSeleccionado.bank_information);
                setBeneficiaryBank(clienteSeleccionado.beneficiary_bank);
                setQualityStandards(clienteSeleccionado.quality_standards);
                setEspecificacionesLC(clienteSeleccionado.especificaciones_lc);
                setAdvance(clienteSeleccionado.advance);
                setCAD(clienteSeleccionado.cad);
                setPuerto(clienteSeleccionado.puerto);
                seleccionPT(clienteSeleccionado.payment_terms);
                setDocumentos(clienteSeleccionado.documentos);
                setProductosCliente(clienteSeleccionado.productos);
    
                if (clienteSeleccionado.subclientes.length > 0) {
                    setShowSubClientes(true);
                } else {
                    setShowSubClientes(false);
                    setSubCliente(null);
                    setDireccionDestino(clienteSeleccionado.direccion);
                }
            }
        }
    };

    const seleccionSubCliente = (subcliente) => {
        if(subcliente !== 0){
            const subclienteSeleccionado = subclientes.find(cli => cli.id_subcliente === parseInt(subCliente));
            if(subclienteSeleccionado){
                    setSubCliente(subclienteSeleccionado.id_subcliente)
                    setDireccionDestino(subclienteSeleccionado.direccion);
            }
        }
    }

    const columnsProductos = [
        {
            name: 'Producto',
            selector: row => {
                if (row.id_producto) {
                    const productoEncontrado = productos.find(prod => parseInt(prod.id_producto) === parseInt(row.id_producto));
                    if (productoEncontrado){
                        const categoriaEncontrada = categorias.find(categoria => parseInt(categoria.id_categoria) === parseInt(productoEncontrado.categoria))
                        const especieEncontrada = especies.find(especie => parseInt(especie.id_especie) === parseInt(productoEncontrado.especie))
                        const medidaEncontrada = medidas.find(medida => parseInt(medida.id_medida) === parseInt(productoEncontrado.medida))
                        return categoriaEncontrada.nombre + " de " + especieEncontrada.nombre + " " + productoEncontrado.espesor + " X " + medidaEncontrada.ancho + " X " + medidaEncontrada.largo  + "(" + medidaEncontrada.medida + ")"
                    }
                }
            },
            width: '25%',
        },
        {
            name: 'Calidad',
            selector: row => {
                if(row.calidad){
                    const calidadEncontrada = calidades.find(c => parseInt(c.id_calidad) === parseInt(row.calidad))
                    if(calidadEncontrada){
                        return calidadEncontrada.calidad
                    }
                }
            },
            width: '10%',
        },
        {
            name: 'Real Size',
            selector: row => {
                if (row.id_producto) {
                    const productoEncontrado = productos.find(prod => parseInt(prod.id_producto) === parseInt(row.id_producto));
                    if (productoEncontrado){
                        const medidaEncontrada = medidas.find(medida => parseInt(medida.id_medida) === parseInt(productoEncontrado.medida))
                        return productoEncontrado.espesor + " X " + medidaEncontrada.anchor + " X " + medidaEncontrada.largor
                    }else{
                        return row.real_size
                    }
                }
            },
            width: '15%',
        },
        {
            name: 'm3',
            selector: row => row.m3,
            width: '10%',
        },
        {
            name: `${getCurrencySymbol(moneda)}/m3`,
            selector: row => row.usd_m3,
            width: '10%',
        },
        {
            name: `Total ${getCurrencySymbol(moneda)}`,
            selector: row => row.usd_total,
            width: '10%',
        },
        {
            name: 'Editar',
            cell: row => (
              <button 
                type="button" 
                className="btn btn-outline-primary"
                onClick={() => handleEditProducto(row)}
              >
                Editar
              </button>
            ),
            ignoreRowClick: true,
            width: '100px',
        },
        {
            name: 'Eliminar',
            cell: row => (
                <div className="delete-button-container">
                    <button 
                        type="button"
                        className="btn btn-outline-danger delete-button"
                        onClick={() => {
                            if(window.confirm('¿Estás seguro?')) {
                                handleEliminarProducto(row)
                            }
                        }}
                    >
                        X
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            width: '100px',
        },
    ];

    const columnsDocumentos = [
        {
            name: 'Documentos',
            selector: row => {
                if (row) {
                    const docEncontrado = documentos.find(d => parseInt(d.id_documento) === parseInt(row));
                    if (docEncontrado){
                        return docEncontrado.nombre
                    }
                }
            },
        },
        {
            name: 'Eliminar',
            cell: row => (
                <div className="delete-button-container">
                    <button 
                        type="button"
                        className="btn btn-outline-danger delete-button"
                        onClick={() => {
                            if(window.confirm('¿Estás seguro?')) {
                                handleDeleteDocumento(row)
                            }
                        }}
                    >
                        X
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            width: '100px',
        },
    ];
    

    const handleEliminarProducto = (producto) => {
        console.log(producto)
        console.log(productosp)
        const productosActualizados = productosp.filter(p => !(p.id_producto === producto.id_producto && p.calidad === producto.calidad));
        console.log(productosActualizados)
        setProductos(productosActualizados);
    };

    const handleAgregarProducto = () => {
        if (productoSeleccionado && calidadSeleccionada) {
            const producto = productos.find(prod => parseInt(prod.id_producto) === parseInt(productoSeleccionado));
            const calidad = calidades.find(cal => parseInt(cal.id_calidad) === parseInt(calidadSeleccionada));
            setProductoSeleccionado(null);
            setCalidadSeleccionada(null);
            if (editMode && productoEnEdicion) {
                // Editar el producto existente
                const updatedProductos = productosp.map(prod => {
                  if (prod.id_producto === productoEnEdicion.id_producto && prod.calidad === productoEnEdicion.calidad) {
                    return {
                      ...prod,
                      m3: m3,
                      usd_m3: usd_m3,
                      usd_total: m3 * usd_m3
                    };
                  }
                  return prod;
                });
                setProductos(updatedProductos);
                setEditMode(false);
            }else{
                if (producto && calidad) {
                    const categoriaSeleccionada = categorias.find(categoria => parseInt(categoria.id_categoria) === parseInt(producto.categoria));
                    const especieSeleccionada = especies.find(especie => parseInt(especie.id_especie) === parseInt(producto.especie));
                    const medidaEncontrada = medidas.find(m => m.id_medida === producto.medida);

                    if (categoriaSeleccionada && especieSeleccionada && medidaEncontrada) {
                        const productoCumpleRestricciones = productosp.every(product => {
                            const productoExistente = productos.find(prod => parseInt(prod.id_producto) === parseInt(product.id_producto));
                            if (productoExistente) {
                                const categoriaExistente = categorias.find(categoria => parseInt(categoria.id_categoria) === parseInt(productoExistente.categoria));
                                const especieExistente = especies.find(especie => parseInt(especie.id_especie) === parseInt(productoExistente.especie));
                                return categoriaExistente.id_categoria === categoriaSeleccionada.id_categoria && especieExistente.id_especie === especieSeleccionada.id_especie;
                            }
                            return true;
                        });
                    
                        if (productoCumpleRestricciones) {
                            const nuevo = {
                                id_producto: parseInt(producto.id_producto),
                                calidad: parseInt(calidadSeleccionada),
                                real_size: `${medidaEncontrada.espesorr} x ${medidaEncontrada.anchor} x ${medidaEncontrada.largor}`,
                                m3: parseFloat(m3),
                                usd_m3: parseFloat(usd_m3),
                                usd_total: parseFloat(m3) * parseFloat(usd_m3)
                            };

                            setProductos([...productosp, nuevo]);
                            setDescripcionProducto(`${especieSeleccionada.nombre_ingles} ${categoriaSeleccionada.nombre_ingles}`);
                            setM3('');
                            setUsdM3('');
                        
                            recalcularProductosDisponibles();
                        } else {
                            alert("No se pueden agregar productos de diferentes categorías o especies.");
                        }
                    } else {
                        alert("Categoría, especie o medida no encontrada.");
                    }
                } else {
                        alert("Debe seleccionar tanto un producto como una calidad.");
                }
            }
        } else {
            alert("Producto o calidad no seleccionada.");
        }
    };
    useEffect(() => {
        const totalUSD = productosp.reduce((acc, producto) => acc + parseFloat(producto.usd_total || 0), 0);
        setValorProforma(totalUSD);
    }, [productosp]);

    const handleDeleteDocumento = (id) => {
        const updatedDocumentos = tdocumentos.filter((doc) => doc !== id);
        setDocumentos(updatedDocumentos);
    };

    const handleAgregarDocumento = () => {
        if (nuevoDocumento) {
            const documentoSeleccionado = documentos.find(d => parseInt(d.id_documento) === parseInt(nuevoDocumento));
            if (documentoSeleccionado) {
                const nuevo = documentoSeleccionado.id_documento;
                setDocumentos([...tdocumentos, nuevo]);
                setNuevoDocumento(null);
            } else {
                alert("No se puede agregar el documento seleccionado.");
            }
        }
    };

    const seleccionPT = (paymentterm) => {
        if (paymentterm !== 0) {
            const ptSeleccionado = pt.find(payment => payment.id_paymentterm === parseInt(paymentterm));
            if (ptSeleccionado) {
                setIndicador1(ptSeleccionado.reglas.includes(1));
                setIndicador2(ptSeleccionado.reglas.includes(2));
                setIndicador3(ptSeleccionado.reglas.includes(3));
                setIndicador4(ptSeleccionado.reglas.includes(4));
                setPaymentTerms(parseInt(paymentterm));
            }
        }
    };
    
    const seleccionIT = (incoterm) =>{
        if (incoterm !== 0) {
            const itSeleccionado = incoterms.find(i => i.id_incoterm === parseInt(incoterm));
            if (itSeleccionado) {
                setIncoTerm(itSeleccionado.id_incoterm);
                setIndicadorIT(itSeleccionado.indicador);
            } else {
                console.log("No se encontró el IncoTerm seleccionado.");
            }
        }
    };
    

    const obtenerCalidadesDisponibles = (productoId) => {
        // Verificación para evitar errores si no hay productoId o productosp
        if (!productoId) {
            return calidades; // Devolver todas las calidades si no hay restricciones
        }
    
        console.log(productosp);
        console.log(productoId);
    
        // Filtrar los productos usados para el producto específico
        const productosUsados = productosp?.filter(prod => prod.id_producto === parseInt(productoId));
        console.log(productosUsados);
    
        // Crear un array para almacenar las calidades ya utilizadas
        let calidadesUsadas = [];
    
        // Añadir las calidades usadas al array
        productosUsados.forEach(prod => {
            if (prod) {
                calidadesUsadas.push(prod.calidad);
            }
        }); 
    
        console.log(calidadesUsadas);

        const prodSelect = productos.find(prod => prod.id_producto === parseInt(productoId));

        console.log(prodSelect)

        if (!prodSelect || !prodSelect.calidades) {
            return calidades; // Si no se encuentra el producto o no tiene calidades, devolver un array vacío
        }
    
        // Devolver las calidades que no están en el array de calidades usadas
        return calidades.filter(cal => !calidadesUsadas.includes(cal.id_calidad) && prodSelect.calidades.includes(cal.id_calidad));
    };
    

    const recalcularProductosDisponibles = () => {
        const productosFiltrados = productos.filter(producto => {
            const calidadesDisponibles = obtenerCalidadesDisponibles(producto.id_producto);
            console.log(calidadesDisponibles)
            return calidadesDisponibles.length > 0;
        });
        console.log(productosFiltrados)
        setProductosDisponibles(productosFiltrados);
    };

    const handleEditProducto = (producto) => {
        setProductoSeleccionado(producto.id_producto);
        setCalidadSeleccionada(producto.calidad);
        setM3(producto.m3);  // Actualizar m3
        setUsdM3(producto.usd_m3);  // Actualizar usd_m3
        setEditMode(true);  // Activar el modo de edición
        setProductoEnEdicion(producto);  // Guardar el producto en edición
    };

    return(
        <div className='modal-overlay'>
            <Modal className='alerta'show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton className={modalContent.variant === 'success' ? 'title-success' : 'title-danger'}>
                <Modal.Title>
                    {modalContent.title}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent.body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <div className='card-title' style={{ textAlign: 'center' }}>Agregar Proforma Invoice</div>
                        </div>
                        <div className='card-body'>
                            <div className="input-group mb-3">
                                <span className='input-group-text'>Basado en Proforma</span>
                                <select
                                    className="form-select"
                                    value={proformaBase}
                                    onChange={(e) => setProformaBase(e.target.value)}
                                >
                                    <option value="">Seleccione una proforma base</option>
                                    {proformas.map((p) => (
                                        <option key={p.id_proforma} value={p.id_proforma}>
                                            {p.descripcion_producto} - {p.fecha}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-group mb-3">
                                <span className='input-group-text'>Moneda</span>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="usd"
                                        value={0}
                                        checked={moneda === 0}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="usd">USD</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="eur"
                                        value={1}
                                        checked={moneda === 1}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="eur">EUR</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="clp"
                                        value={2}
                                        checked={moneda === 2}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="clp">CLP</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="brl"
                                        value={3}
                                        checked={moneda === 3}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="brl">BRL</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="moneda"
                                        id="pyg"
                                        value={4}
                                        checked={moneda === 4}
                                        onChange={(e) => setMoneda(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="pyg">PYG</label>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <span className='input-group-text'>Empresa Emisor</span>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="empresaEmisor"
                                        id="ptc"
                                        value={0}
                                        checked={empresaEmisor === 0}
                                        onChange={(e) => setEmpresaEmisor(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="ptc">PTC</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="empresaEmisor"
                                        id="ptb"
                                        value={1}
                                        checked={empresaEmisor === 1}
                                        onChange={(e) => setEmpresaEmisor(parseInt(e.target.value))}
                                    />
                                    <label className="form-check-label" htmlFor="ptb">PTB</label>
                                </div>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Fecha de Emisión</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={fecha}
                                    onChange={(e) => setFecha(e.target.value)}
                                />
                            </div>
                            <div className="input-group mb-3">
                              <span className='input-group-text'>Cliente</span>
                              <select
                                className="form-select"
                                value={cliente}
                                onChange={(e) => seleccionCliente(e.target.value)}
                              >
                                <option value="">Seleccione un cliente</option>
                                {clientes.map((cli) => (
                                  <option key={cli.id_cliente} value={cli.id_cliente}>
                                    {cli.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {showSubClientes && 
                                <div className="input-group mb-3">
                                  <span className='input-group-text'>Sub-Cliente</span>
                                  <select
                                    className="form-select"
                                    value={subCliente}
                                    onChange={(e) => seleccionSubCliente(e.target.value)}
                                  >
                                    <option value="">Seleccione un Sub-Cliente</option>
                                    {subclientes.map((cli) => (
                                      <option key={cli.id_subcliente} value={cli.id_subcliente}>
                                        {cli.nombre}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                            }
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Fecha Embarque</span>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={fecha_embarque}
                                    onChange={(e) => setFechaEmbarque(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Origen</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Origen"
                                    value={origen}
                                    onChange={(e) => setOrigen(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Payment Term</span>
                                <select 
                                    className="form-control"
                                    value={payment_terms}
                                    onChange={(e) => seleccionPT(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar Payment Term</option>
                                    {pt.map(paymentterm => (
                                        <option key={paymentterm.id_paymentterm} value={paymentterm.id_paymentterm}>
                                            {paymentterm.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {indicador4 && 
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Advance</span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Ingrese % advance"
                                      value={advance}
                                      onChange={(e) => setAdvance(e.target.value)}
                                    />
                                    <span className='input-group-text'>CAD</span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Ingrese % CAD"
                                      value={cad}
                                      onChange={(e) => setCAD(e.target.value)}
                                    />
                                </div>
                            }
                            <div className="input-group mb-3">
                              <span className='input-group-text'>IncoTerm</span>
                              <select
                                className="form-select"
                                value={incoterm}
                                onChange={(e) => seleccionIT(e.target.value)}
                              >
                                <option value="">Seleccione un IncoTerm</option>
                                {incoterms.map((it) => (
                                  <option key={it.id_incoterm} value={it.id_incoterm}>
                                    {it.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {indicadorIT === 1 &&
                                <div className="input-group mb-3">
                                  <span className='input-group-text'>Puerto</span>
                                  <select
                                    className="form-select"
                                    value={puerto}
                                    onChange={(e) => setPuerto(e.target.value)}
                                  >
                                    <option value="">Seleccione un Puerto de Destino</option>
                                    {puertos.map((puerto) => (
                                      <option key={puerto.id_puerto} value={puerto.id_puerto}>
                                        {puerto.nombre}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                            }
                            {indicadorIT === 3 &&
                                <div className='input-group mb-3'>
                                    <span className='input-group-text' style={{width:'160px'}}>Dirección Despacho</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Ingrese Dirección"
                                        value={direccion_destino}
                                        onChange={(e) => setDireccionDestino(e.target.value)}
                                    />
                                </div>
                            }
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Descripcion Producto</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={descripcion_producto}
                                    disabled
                                />
                            </div>
                            <div className='mb-3'>
                                <h5 style={{ textAlign: 'center' }}>Productos</h5>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>
                                        Producto
                                    </span>
                                    <select
                                        value={productoSeleccionado}
                                        onChange={e => setProductoSeleccionado(e.target.value)}
                                        className='form-control'
                                    >
                                        <option value={null}>Seleccionar Producto</option>
                                        {productosDisponibles?.map((producto) => {
                                            const productoEnCliente = productosCliente.some(prod => parseInt(prod) === parseInt(producto.id_producto));
                                            if (productoEnCliente) {
                                                const categoriaProducto = categorias.find(categoria => categoria.id_categoria === producto.categoria);
                                                const especieProducto = especies.find(especie => especie.id_especie === producto.especie);
                                                const medidaProducto = medidas.find(medida => medida.id_medida === producto.medida);
                                                // Si no hay un producto seleccionado, mostrar todas las opciones disponibles
                                                if (!descripcion_producto) {
                                                    if (categoriaProducto && especieProducto && medidaProducto) {
                                                        return (
                                                            <option key={producto.id_producto} value={producto.id_producto}>
                                                                {categoriaProducto.nombre + " de " + especieProducto.nombre + " - " + producto.espesor + " X " + medidaProducto.ancho + " X " + medidaProducto.largo + "(" + medidaProducto.medida + ")"}
                                                            </option>
                                                        );
                                                    }
                                                } else {
                                                    // Verificar si el nombre del producto coincide con descProducto
                                                    if (categoriaProducto && especieProducto && medidaProducto &&
                                                        `${especieProducto.nombre_ingles} ${categoriaProducto.nombre_ingles}` === descripcion_producto) {
                                                        return (
                                                            <option key={producto.id_producto} value={producto.id_producto}>
                                                                {categoriaProducto.nombre + " de " + especieProducto.nombre + " - " + producto.espesor + " X " + medidaProducto.ancho + " X " + medidaProducto.largo + "(" + medidaProducto.medida + ")"}
                                                            </option>
                                                        );
                                                    }
                                                }
                                            }
                                            return null; // Omitir esta opción si no cumple los requisitos
                                        })}
                                    </select>
                                </div>
                                {productoSeleccionado && (
                                    <div className='input-group mb-3'>
                                        {!editMode &&(
                                            <>
                                            <span className='input-group-text'>
                                                Calidad
                                            </span>
                                            <select
                                                value={calidadSeleccionada}
                                                onChange={e => setCalidadSeleccionada(e.target.value)}
                                                className='form-control'
                                            >
                                                <option value="">Seleccionar Calidad</option>
                                                {obtenerCalidadesDisponibles(productoSeleccionado).map(cal => (
                                                    <option key={cal.id_calidad} value={cal.id_calidad}>
                                                        {cal.calidad}
                                                    </option>
                                                ))}
                                            </select>
                                            </>
                                        )}
                                        <span className='input-group-text'>m3</span>
                                        <input 
                                            type="number" 
                                            value={m3} 
                                            onChange={(e) => setM3(e.target.value)} 
                                            className="form-control" 
                                        />
                                        <span className='input-group-text'>{getCurrencySymbol(moneda)}/m3</span>
                                        <input 
                                            type="number" 
                                            value={usd_m3} 
                                            onChange={(e) => setUsdM3(e.target.value)} 
                                            className="form-control" 
                                        />
                                    </div>
                                )}
                                <button 
                                  type="button" 
                                  onClick={handleAgregarProducto} 
                                  className="btn btn-outline-primary" 
                                  disabled={!productoSeleccionado || !calidadSeleccionada}
                                >
                                  {editMode ? 'Editar Producto' : 'Agregar Producto'}
                                </button>
                                <DataTable 
                                  columns={columnsProductos}
                                  data={productosp}
                                  highlightOnHover
                                  dense
                                  pagination={true}   
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Valor Proforma (${getCurrencySymbol(moneda)})</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={valor_proforma}
                                    disabled
                                />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text" style={{ width: '170px' }}>Allowance</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Ingrese allowance"
                                    value={allowance}
                                    onChange={(e) => setAllowance(e.target.value)}
                                />
                            </div>
                            {indicador1 &&
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Beneficiary Bank</span>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Ingrese beneficiary bank"
                                      value={beneficiary_bank}
                                      onChange={(e) => setBeneficiaryBank(e.target.value)}
                                    />
                                </div>
                            }
                            {indicador2 &&
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Bank Information</span>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Ingrese Información del Banco"
                                      value={bank_information}
                                      onChange={(e) => setBankInformation(e.target.value)}
                                    />
                                </div>
                            }
                            {indicador3 &&
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Especificaciones Línea de Crédito</span>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Ingrese especificaciones de línea de crédito"
                                      value={especificaciones_lc}
                                      onChange={(e) => setEspecificacionesLC(e.target.value)}
                                    />
                                </div>
                            }
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Quality Standards</span>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Quality Standards"
                                    value={quality_standards}
                                    onChange={(e) => setQualityStandards(e.target.value)}
                                />
                            </div>
                            <div className='mb-3'>
                                <h5 style={{ textAlign: 'center' }}>Documentos Requeridos</h5>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>
                                        Documento
                                    </span>
                                    <select
                                        value={nuevoDocumento}
                                        onChange={e => setNuevoDocumento(e.target.value)}
                                        className='form-control'
                                    >
                                        <option value="">Seleccionar Documento</option>
                                        {documentos.map((td) => {
                                            const tdExistente = tdocumentos.some(dr => parseInt(dr) === parseInt(td.id_documento));
                                            if (!tdExistente) {
                                                return (
                                                    <option key={td.id_documento} value={td.id_documento}>
                                                        {td.nombre}
                                                    </option>
                                                );
                                            }
                                            return null; // Omitir esta opción si no cumple los requisitos
                                        })}
                                    </select>
                                    <button type="button" onClick={handleAgregarDocumento} className="btn btn-outline-primary" disabled={!nuevoDocumento}>
                                        Agregar Documento
                                    </button>
                                </div>
                                <DataTable 
                                  columns={columnsDocumentos}
                                  data={tdocumentos}
                                  highlightOnHover
                                  dense
                                  pagination={true}   
                                />
                            </div>
                            <div className="input-group mb-3">
                              <span className='input-group-text'>Remitente</span>
                              <select
                                className="form-select"
                                value={remitente}
                                onChange={(e) => setRemitente(e.target.value)}
                              >
                                <option value="">Seleccione un Remitente</option>
                                {usuarios.map((user) => (
                                  <option key={user.id_usuario} value={user.id_usuario}>
                                    {user.nombre}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="input-group mb-3">
                                <span className='input-group-text'>Aprobador</span>
                                <select
                                    className="form-select"
                                    value={aprobador}
                                    onChange={(e) => setAprobador(e.target.value)}
                                >
                                    <option value="">Seleccione un Aprobador</option>
                                    {usuarios
                                        .filter(user => user.rol === 1) // Filter users with role 1
                                        .map(user => (
                                            <option key={user.id_usuario} value={user.id_usuario}>
                                                {user.nombre}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Observaciones Proforma</span>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={observaciones_proforma}
                                    onChange={(e) => setObservacionesProforma(e.target.value)}
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Observaciones Adicionales</span>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={observaciones_adicionales}
                                    onChange={(e) => setObservacionesAdicionales(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <div className='mb-3'>
                            <button 
                                type="button" 
                                className="btn btn-outline-warning mb-3 mt-3" 
                                onClick={(event) => {
                                    setEsBorrador(true); // Marcar como borrador
                                    handleSubmit(event, true);
                                }}
                            >
                                Guardar Como Borrador
                            </button>
                            </div>
                            <button 
                                type="submit" 
                                className="btn btn-outline-success mb-3 mt-3"
                                disabled={botonDeshabilitado}
                                onClick={(event) => {
                                    setEsBorrador(false); // Marcar como finalizado
                                    handleSubmit(event, false);
                                }}
                            >
                                Guardar
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AgregarProforma