import React, { useState, useEffect, useRef, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import { FaFilePdf } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { Modal, Button, Spinner } from 'react-bootstrap';
import EditarNotaFiscal from './EditarNotaFiscal';
import * as XLSX from 'xlsx';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const NotasFiscales = () => {
    const [loading, setLoading] = useState(true);
    const [dataNF, setDataNF] = useState([]);
    const [dataProveedores, setDataProveedores] = useState([]);
    const [dataDetalle, setDataDetalle] = useState([]);
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', body: '', variant: '' });
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedProveedor, setSelectedProveedor] = useState('');
    const [selectedTipoNF, setSelectedTipoNF] = useState('');


    const fetchData = async () => {
        const token = localStorage.getItem('token');
        try {
            const responseNF = await fetch('https://api.dvstarlab.com/notasfiscales', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseNF.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataNF = await responseNF.json();
            setDataNF(dataNF);

            const responseProveedores = await fetch('https://api.dvstarlab.com/proveedores', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (responseProveedores.status === 401) {
                throw new Error('Unauthorized');
            }
            const dataProveedores = await responseProveedores.json();
            setDataProveedores(dataProveedores);

            setLoading(false);
        }catch(error){
            console.error('Error fetching data:', error);
            setLoading(false);
            handleError("Error recibiendo los datos.");
        }
    };

    const handleError = (message) => {
        setModalContent({
            title: "Error",
            body: message,
            variant: "danger"
        });
        setShowModal(true);
    };    

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    const proveedoresMap = useMemo(() => {
        return dataProveedores.reduce((acc, prov) => {
            acc[prov.id_proveedor] = prov.nombre;
            return acc;
        }, {});
    }, [dataProveedores]);   

    useEffect(() => {
        const filterByText = dataNF.filter(row =>
            Object.values(row).some(value =>
                value?.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
    
        const filterByDate = filterByText.filter(row => {
            const date = new Date(row.emision);
            const monthMatch = selectedMonth ? date.getUTCMonth() + 1 === parseInt(selectedMonth) : true;
            const yearMatch = selectedYear ? date.getUTCFullYear() === parseInt(selectedYear) : true;
            return monthMatch && yearMatch;
        });
    
        const filterByProveedor = filterByDate.filter(row =>
            selectedProveedor ? row.id_proveedor === parseInt(selectedProveedor) : true
        );

        const filterByTipoNF = filterByProveedor.filter(row => {
            if (selectedTipoNF === "entrada") {
                return row.tipo_nf === "0" || row.tipo_nf === "2";
            }
            return selectedTipoNF ? row.tipo_nf === selectedTipoNF : true;
        });
    
        const filteredWithProveedor = filterByTipoNF.map(row => ({
            ...row,
            proveedor_nombre: proveedoresMap[row.id_proveedor] || 'Desconocido',
            valor_total: row.tipo_nf === "0" || row.tipo_nf === "2" ? -Math.abs(row.valor_total) : row.valor_total,
        }));
    
        setFilteredData(filteredWithProveedor);
    }, [dataNF, filterText, selectedMonth, selectedYear, selectedProveedor, selectedTipoNF, proveedoresMap]);    
    

    const columns = [
        {
            name: 'Nro NF',
            selector: row => row.nro_nf,
            sortable: true,
        },
        {
            name: 'Proveedor',
            selector: row => row.proveedor_nombre,
            sortable: true,
        },
        {
            name: 'Fecha Emisión',
            selector: row => formatDate(row.emision),
            sortable: true,
        },
        {
            name: 'Booking',
            selector: row => row.booking? row.booking : 'N/A',
        },
        {
            name: 'Valor Total',
            selector: row => row.valor_total,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: row => (
                <div className="d-flex gap-2">
                    <button onClick={() => handleEditar(row)} className="btn btn-sm btn-outline-warning">
                        <LuPencilLine />
                    </button>
                    <button onClick={() => handleEliminar(row.id_nota_fiscal)} className="btn btn-sm btn-outline-danger">
                        <IoTrashOutline />
                    </button>
                    <button onClick={() => handleVerDetalles(row.id_nota_fiscal)} className="btn btn-sm btn-outline-primary">
                        <FaEye />
                    </button>
                </div>
            ),
        },
    ];

    const handleAgregar = () => {
        setShowAgregar(true);
    };

    const handleEditar = (nf) => {
        setSelected(nf);
        setShowEditar(true);
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    };

    const handleSaveEditar = (edited) => {
        const token = localStorage.getItem('token');
        fetch(`https://api.dvstarlab.com/editarnotafiscal/${edited.id_nota_fiscal}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(edited),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al actualizar datos');
                }
                return response.json();
            })
            .then(data => {
                alert('Datos actualizados exitosamente');
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleExportExcel = async () => {
        const token = localStorage.getItem('token');
        try {
            // Obtener los IDs de las Notas Fiscales filtradas
            const ids = filteredData.map(nf => nf.id_nota_fiscal).join(',');
    
            // Realizar una solicitud al nuevo endpoint
            const response = await fetch(`https://api.dvstarlab.com/notasfiscales/detalles?ids=${ids}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const detallesCompletos = await response.json();
    
            // Mapear los detalles a las columnas del Excel
            const allData = detallesCompletos.map(detalle => {
                const nf = filteredData.find(n => n.id_nota_fiscal === detalle.id_nota_fiscal);
                return {
                    'Nro NF': nf.nro_nf,
                    'Proveedor': nf.proveedor_nombre,
                    'Fecha Emisión': formatDate(nf.emision),
                    'Booking': nf.booking? nf.booking : 'N/A',
                    'Valor Total NF': detalle.valor_total,
                    'Producto': detalle.producto,
                    'Calidad': detalle.calidad,
                    'Medida': detalle.medida,
                    'NCM': detalle.ncm,
                    'M3': detalle.m3,
                    'USD/M3': detalle.usd_m3,
                    'Total': detalle.total,
                    'Descuento': detalle.descuento,
                };
            });
    
            // Generar el archivo Excel
            const ws = XLSX.utils.json_to_sheet(allData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Detalles_Notas_Fiscales");
            XLSX.writeFile(wb, "Detalles_Notas_Fiscales.xlsx");
        } catch (error) {
            console.error('Error al generar el Excel:', error);
        }
    };    

    const handleEliminar = (idAEliminar) => {
        const confirmacion = window.confirm('¿Estás seguro de que deseas eliminar esta Nota Fiscal? Esta acción no se puede deshacer.');
        if (confirmacion) {
            const token = localStorage.getItem('token');
            fetch(`https://api.dvstarlab.com/eliminarnotafiscal/${idAEliminar}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.ok) {
                        alert('Nota Fiscal eliminada exitosamente.');
                        fetchData(); // Recargar datos
                    } else {
                        alert('Error al intentar eliminar la Nota Fiscal.');
                    }
                })
                .catch(error => {
                    console.error('Error de red:', error);
                    alert('Ocurrió un error al intentar eliminar la Nota Fiscal.');
                });
        }
    };    

    const handleVerDetalles = async (notaFiscalId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://api.dvstarlab.com/notasfiscales/${notaFiscalId}/detalles`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Error al obtener los detalles');
            }
            const detalles = await response.json();
            setDataDetalle(detalles);
            setShowModal(true);
            setModalContent({
                title: `Detalles de Nota Fiscal ${notaFiscalId}`,
                body: (
                    <ul>
                        {detalles.map((detalle, index) => (
                            <li key={index}>{detalle.producto} - Total: {detalle.total}</li>
                        ))}
                    </ul>
                ),
                variant: 'primary',
            });
        } catch (error) {
            console.error('Error al obtener detalles:', error);
        }
    };

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Notas Fiscales</h3>
                </div>
                <div className='card-body mb-3'>
                    {loading ? (
                        <>
                            <div className="text-center">
                                <Spinner animation="border" role="status" />
                            </div>
                            <div className='text-center'>
                                <span className="sr-only">Cargando...</span>
                            </div>
                        </>
                    ) : (
                        <>
                            {filteredData.length === 0 && !loading && (
                                <div className="alert alert-warning text-center">
                                    No se encontraron notas fiscales con los criterios seleccionados.
                                </div>
                            )}
                            <div className='input-group mb-3'>
                                <input
                                    type="text"
                                    placeholder='Buscar...'
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                    className='form-control'
                                />
                                <select
                                    onChange={(e) => setSelectedProveedor(e.target.value)}
                                    className='form-select'
                                >
                                    <option value="">Todos los Proveedores</option>
                                    {dataProveedores.map(prov => (
                                        <option key={prov.id_proveedor} value={prov.id_proveedor}>
                                            {prov.nombre}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className='form-select'
                                >
                                    <option value="">Mes</option>
                                    {[...Array(12)].map((_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                            {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className='form-select'
                                >
                                    <option value="">Año</option>
                                    {[...Array(5)].map((_, i) => {
                                        const year = new Date().getFullYear() - i;
                                        return <option key={year} value={year}>{year}</option>;
                                    })}
                                </select>
                                <select
                                    value={selectedTipoNF}
                                    onChange={(e) => setSelectedTipoNF(e.target.value)}
                                    className="form-select"
                                >
                                    <option value="">Todos los Tipos</option>
                                    <option value="entrada">Entrada</option>
                                    <option value="1">Salida</option>
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <button type='button' className='btn btn-outline-primary' onClick={() => handleAgregar()}>Agregar Nota Fiscal</button>
                                <button type='button' className='btn btn-outline-success' onClick={handleExportExcel}>Extraer Excel</button>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        noHeader
                                        responsive
                                        pagination
                                        paginationPerPage={30}
                                        highlightOnHover
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Modal para Detalles */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent.body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {showEditar && (
                <EditarNotaFiscal
                    notaFiscal={selected}
                    cerrar={handleCloseEditar}
                    guardar={handleSaveEditar}
                    proveedores={dataProveedores}
                />
            )}
        </div>
    );
};

export default NotasFiscales;
