import React, { useState, useEffect, useCallback } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ptcImage from '../../imagenes/ptc.jpg';
import ptbImage from '../../imagenes/ptb.jpg';

const GenerarPDF = ({ proforma, clientes, incoterms, puertos, productos, documentos, usuarios, categorias, especies, medidas, calidades, proformas, shippingdocs, pt, cerrar }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        return `${day}-${month}-${year}`;
    };

    function formatMonthYear(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long' }; // Muestra el mes completo y el año
        return date.toLocaleDateString('en-US', options);
    }

    function formatNumber(value) {
        return new Intl.NumberFormat('en-US', { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        }).format(value);
    }

    const [infoCliente, setInfoCliente] = useState(null);
    const [nroProforma, setNroProforma] = useState(proforma.correlativo);
    const [mCubicos, setMCubicos] = useState(null);
    const [contador, setContador] = useState(0);

    const getCurrencySymbol = (moneda) => {
        switch(moneda) {
            case 0: return 'USD';
            case 1: return 'EUR';
            case 2: return 'CLP';
            case 3: return 'BRL';
            case 4: return 'PYG';
            default: return 'USD';
        }
    };


    useEffect(() => {
        if (proforma.cliente && !infoCliente) {
            const clienteInfo = clientes.find(cli => cli.id_cliente === proforma.cliente);
            if (clienteInfo) {
                setInfoCliente(clienteInfo);
            }
        }
    }, [proforma.cliente, infoCliente, clientes, proformas]);

    useEffect(() => {
        if (proforma.productos.length > 0 && !mCubicos) {
            const total_m3 = proforma.productos.reduce((total, _, index) => total + parseFloat(proforma.m3[index]), 0);
            setMCubicos(total_m3);
        }
    }, [proforma.productos, mCubicos]);

    const generatePDF = useCallback((fontSize = 11, measureOnly = false, imageSize = 60, spacing = 5) => {
        const doc = new jsPDF();
        let y = 10;
        doc.setFontSize(fontSize);

        // Agregar logo
        if(proforma.empresa_emisor === 0){
            doc.addImage(ptcImage, 'JPEG', 15, y, imageSize, imageSize * 0.33); // Ajuste dinámico del tamaño del logo
            y += imageSize * 0.33 + spacing;
        }else if(proforma.empresa_emisor === 1){
            doc.addImage(ptbImage, 'JPEG', 15, y, imageSize, imageSize * 0.33); // Ajuste dinámico del tamaño del logo
            y += imageSize * 0.33 + spacing;
        }else{
            doc.addImage(ptcImage, 'JPEG', 15, y, imageSize, imageSize * 0.33); // Ajuste dinámico del tamaño del logo
            y += imageSize * 0.33 + spacing;
        }

        // Título
        doc.setFont('helvetica', 'bold');
        const year = new Date(proforma.fecha_embarque).getUTCFullYear().toString().substr(-2);
        const title = `Proforma ${infoCliente?.siglas} ${nroProforma < 10 ? '0' + nroProforma : nroProforma}-${year}`;
        const titleX = (doc.internal.pageSize.getWidth() - doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2;
        doc.text(title.toUpperCase(), titleX, y);
        y += 5;

        // Fecha
        doc.setFontSize(fontSize - 2);
        const dateText = `Date: ${formatDate(proforma.fecha)}`;
        const dateX = doc.internal.pageSize.getWidth() - doc.getStringUnitWidth(dateText) * doc.internal.getFontSize() / doc.internal.scaleFactor - 15;
        doc.text(dateText, dateX, y);
        y += 5;

        // Información del cliente
        const paymentTermObj = pt.find(payment => payment.id_paymentterm === parseInt(proforma.payment_terms)) || {};
        const paymentTermNombre = paymentTermObj.nombre || 'N/A';

        doc.setFontSize(fontSize);
        doc.text('CUSTOMER', 15, y);
        doc.autoTable({
            startY: y + 5,
            body: [
                [`${infoCliente?.nombre}`, `Shipment Date: ${formatMonthYear(proforma.fecha_embarque)}`],
                [`Address: ${infoCliente?.direccion}`, ''],
                [`TAX ID: ${infoCliente?.tax}`, ''],
                [`Attn: ${infoCliente?.contacto[0]}`, `Payment Terms: ${parseInt(proforma.payment_terms) ===2 ? `${proforma.advance || '0'}% payment by order and ${proforma.cad || '0'}% wire transfer after shipment together with shipping documents` : paymentTermNombre}`],
            ],
            columnStyles: { 0: { cellWidth: 90 }, 1: { cellWidth: 90 } },
            styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top' },
            theme: 'plain',
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            },
        });
        y = doc.lastAutoTable.finalY + 5;

        // Tabla de origen y destino
        const puerto = puertos.find(p => p.id_puerto === proforma.puerto) || {};
        doc.autoTable({
            startY: y,
            body: [
                [`Origin: ${proforma.origen}`, `Port of Origin: Any port in Brazil`],
                ['', `Port of delivery: ${puerto.nombre || 'N/A'}, ${puerto.pais || 'N/A'}`],
                ['', `Delivery: Port of ${puerto.nombre || 'N/A'}, ${puerto.pais || 'N/A'}`],
                [`Destination: ${puerto.pais || 'N/A'}`, `Sales condition: ${incoterms.find(i => i.id_incoterm === proforma.incoterm)?.nombre || 'N/A'} ${puerto.nombre || 'N/A'}, ${puerto.pais || 'N/A'}`],
            ],
            columnStyles: { 0: { cellWidth: 90 }, 1: { cellWidth: 90 } },
            styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top' },
            theme: 'plain',
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            },
        });
        y = doc.lastAutoTable.finalY + 5;

        // Tabla de embalaje
        doc.autoTable({
            startY: y,
            body: [
                ['', 'Packing: Bundles'],
                ['', 'Packing complies with ISPM-15 standard'],
            ],
            columnStyles: { 0: { cellWidth: 90 }, 1: { cellWidth: 90 } },
            styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top' },
            theme: 'plain',
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            },
        });
        y = doc.lastAutoTable.finalY + 5;

        // Descripción del producto
        doc.autoTable({
            startY: y,
            body: [
                [{ content: 'PRODUCT DESCRIPTION', colSpan: 2, styles: { halign: 'center', fontStyle: 'bold' } }],
                [{ content: `${proforma.descripcion_producto}`, colSpan: 2, styles: { halign: 'center' } }],
            ],
            columnStyles: { 0: { cellWidth: 90 }, 1: { cellWidth: 90 } },
            styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top' },
            theme: 'plain',
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            },
        });
        y = doc.lastAutoTable.finalY + 5;

        // Detalles del producto
        const detallesData = proforma.productos.map((producto, index) => {
            const productoActual = productos.find(p => p.id_producto === producto);
            const medidaProducto = productoActual ? medidas.find(med => med.id_medida === productoActual.medida) : null;
            const calidadProducto = productoActual ? calidades.find(cal => cal.id_calidad === proforma.calidad[index]): null;
            const facturacion = (calidadProducto?.calidad === 'RW'? 'RW' : medidaProducto?.ancho || '') + " X " +(medidaProducto?.largo || '')
            //const facturacion = (medidaProducto?.largo || '') + " X " + (calidadProducto?.cslidad === 'RW'? 'RW' : medidaProducto?.ancho || '')
            const real = (calidadProducto?.calidad === 'RW'? 'RW' : medidaProducto?.anchor || '') + " X " +(medidaProducto?.largor || '')
            //const real = (medidaProducto?.largor || '') + " X " + (calidadProducto?.calidad === 'RW'? 'RW' : medidaProducto?.ancho || '')
            return [
                productoActual?.espesor || '',
                facturacion || '',
                real || '',
                calidadProducto?.calidad || '',
                formatNumber(proforma.m3[index]),        // Formatea m3
                formatNumber(proforma.usd_m3[index]),    // Formatea usd/m3
                formatNumber(proforma.usd_total[index]), // Formatea total usd
            ];
        });

        const total_m3 = proforma.m3.reduce((acc, m3) => acc + parseFloat(m3), 0);
        const total_usd = proforma.usd_total.reduce((acc, total) => acc + parseFloat(total), 0);

        detallesData.push([
            { content: 'TOTAL', styles: { fontStyle: 'bold' } },
            '', '', '', { content: formatNumber(total_m3), styles: { fontStyle: 'bold' } },
            '', { content: getCurrencySymbol(proforma.moneda) + " " + formatNumber(total_usd), styles: { fontStyle: 'bold' } },
        ]);

        doc.autoTable({
            startY: y,
            head: [['Thickness (mm)', 'Invoice Size (mm)', 'Real Size (mm)', 'Type', 'M3', `${getCurrencySymbol(proforma.moneda)}/m3`, `Total ${getCurrencySymbol(proforma.moneda)}`]],
            body: detallesData,
            columnStyles: { 0: { cellWidth: 28 }, 1: { cellWidth: 28 }, 2: { cellWidth: 28 }, 3: { cellWidth: 20 }, 4: { cellWidth: 20 }, 5: { cellWidth: 28 }, 6: { cellWidth: 28 } },
            styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top', halign: 'center' },
            theme: 'plain',
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            },
        });
        y = doc.lastAutoTable.finalY + 5;

        // Información del banco y estándares de calidad
        const mostrarBankInfo = paymentTermObj?.reglas?.includes(2);
        const mostrarBeneficiaryBank = paymentTermObj?.reglas?.includes(1);
        const mostrarEspecificacionesLC = paymentTermObj?.reglas?.includes(3);

        if (mostrarBankInfo || mostrarBeneficiaryBank || mostrarEspecificacionesLC) {
            const bankInfoRows = [];
            if (mostrarBankInfo) {
                bankInfoRows.push(['Bank Information']);
                bankInfoRows.push([proforma.bank_information]);
            }
            if (mostrarBeneficiaryBank) {
                bankInfoRows.push(['Beneficiary Bank']);
                bankInfoRows.push([proforma.beneficiary_bank]);
            }
            if (mostrarEspecificacionesLC) {
                bankInfoRows.push(['Letter of Credit Specifications']);
                bankInfoRows.push([proforma.especificaciones_lc]);
            }
            /* doc.autoTable({
                startY: y,
                body: bankInfoRows,
                columnStyles: { 0: { cellWidth: 90 }, 1: { cellWidth: 90 } },
                styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top' },
                theme: 'plain',
                didDrawCell: function (data) {
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
                },
            }); */

            // Quality Standards y Obs en la misma fila
            doc.autoTable({
                startY: y,
                body: [
                    [{content: bankInfoRows? bankInfoRows[0] : '', styles: {fontStyle:'bold'}},{ content: 'Quality Standards', styles: { fontStyle: 'bold' }}],
                    [bankInfoRows? bankInfoRows[1] : '', proforma.quality_standards],
                    [{content: bankInfoRows? bankInfoRows[2] : '', styles: {fontStyle:'bold'}},{ content: 'Obs', styles: { fontStyle: 'bold' } }],
                    [bankInfoRows? bankInfoRows[3] : '',proforma.observaciones_proforma],
                ],
                columnStyles: { 0: { cellWidth: 90 }, 1: { cellWidth: 90 } },
                styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top' },
                theme: 'plain',
                didDrawCell: function (data) {
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
                },
            });

            y = doc.lastAutoTable.finalY + 5;
        }

        // Documentos requeridos
        const documentosr = proforma.documentos.map(doc => {
            const documentoActual = documentos.find(td => td.id_documento === doc);
            return documentoActual ? documentoActual.nombre : 'x';
        }).join(' / ');

        doc.autoTable({
            startY: y,
            body: [
                [{ content: 'Documents Required', styles: { fontStyle: 'bold' } }],
                [documentosr]
            ],
            columnStyles: { 0: { cellWidth: 180 } },
            styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top' },
            theme: 'plain',
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            },
        });
        y = doc.lastAutoTable.finalY + 5;

        // Espacio para las firmas
        const firmaNombre = proforma.estado === 2 ? "Juan Pablo Opazo\nCommercial Director" : "";
        doc.autoTable({
            startY: y,
            body: [[firmaNombre, '']],
            columnStyles: { 0: { cellWidth: 90 }, 1: { cellWidth: 90 } },
            styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top', minCellHeight: 10, halign: 'center' },
            theme: 'plain',
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            },
        });
        y = doc.lastAutoTable.finalY;

        doc.autoTable({
            startY: y,
            body: [
                [{ content: proforma.empresa_emisor === 0 ? 'Palm Trade Chile SPA' : proforma.empresa_emisor === 1 ? 'Palm Trade Brasil LTDA' : 'Palm Trade Chile SPA' , styles: { fontStyle: 'bold' } }, { content: `${infoCliente?.nombre}`, styles: { fontStyle: 'bold' } }],
            ],
            columnStyles: { 0: { cellWidth: 90 }, 1: { cellWidth: 90 } },
            styles: { cellPadding: 1, fontSize: fontSize - 1, valign: 'top', halign: 'center' },
            theme: 'plain',
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            },
        });

        // Añadir shipping documents si existen
        const shippingDocument = shippingdocs.find(doc => doc.proforma === proforma.id_proforma);
        if (shippingDocument) {
            doc.addPage();
            y = 20;
            doc.setFont('helvetica', 'bold');
            doc.text('SHIPPING DOCUMENTS INFORMATION', 15, y);
            doc.setFont('helvetica', 'normal');
            const documentosData = shippingDocument.documentos.map((documento, index) => [
                documentos.find(td => td.id_documento === documento)?.nombre || '',
                shippingDocument.destinos[index] || '',
                shippingDocument.contenidos[index] || ''
            ]);
            doc.autoTable({
                startY: y + 5,
                head: [['Document', 'Heading', 'Content']],
                body: documentosData,
                columnStyles: { 0: { cellWidth: 50 }, 1: { cellWidth: 50 }, 2: { cellWidth: 80 } },
                styles: { cellPadding: 1, halign: 'left', valign: 'top', minCellHeight: 15 },
                theme: 'plain',
                didDrawCell: function (data) {
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
                },
            });
        }

        const pageCount = doc.internal.getNumberOfPages();
        if (pageCount > 1 && fontSize > 5) {
            generatePDF(fontSize - 1, measureOnly);
        } else if (!measureOnly) {
            doc.save(`${title}.pdf`);
        }
    }, [infoCliente, nroProforma, proforma, formatDate, ptcImage, productos, medidas, incoterms, puertos, documentos, shippingdocs]);

    useEffect(() => {
        if (infoCliente && contador === 0) {
            generatePDF();
            setContador(1);
            cerrar();
        }
    }, [infoCliente, contador, cerrar, generatePDF]);

    return null;
};

export default GenerarPDF;
